// icon:figma | Fontawesome https://fontawesome.com/ | Fontawesome
import * as React from 'react';

function IconFigma(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 384 512'
      fill='currentColor'
      height='1em'
      width='1em'
      {...props}
    >
      <path d='M14 95.792C14 42.888 56.888 0 109.793 0h164.368c52.905 0 95.793 42.888 95.793 95.792 0 33.5-17.196 62.984-43.243 80.105 26.047 17.122 43.243 46.605 43.243 80.105 0 52.905-42.888 95.793-95.793 95.793h-2.08c-24.802 0-47.403-9.426-64.415-24.891v88.263c0 53.61-44.009 96.833-97.357 96.833C57.536 512 14 469.243 14 416.207c0-33.498 17.195-62.98 43.24-80.102C31.193 318.983 14 289.5 14 256.002c0-33.5 17.196-62.983 43.242-80.105C31.197 158.776 14 129.292 14 95.792zm162.288 95.795h-66.495c-35.576 0-64.415 28.84-64.415 64.415 0 35.438 28.617 64.192 64.003 64.414l.412-.001h66.495V191.587zm31.378 64.415c0 35.575 28.839 64.415 64.415 64.415h2.08c35.576 0 64.415-28.84 64.415-64.415s-28.839-64.415-64.415-64.415h-2.08c-35.576 0-64.415 28.84-64.415 64.415zm-97.873 95.793l-.412-.001c-35.386.221-64.003 28.975-64.003 64.413 0 35.445 29.225 64.415 64.931 64.415 36.282 0 65.979-29.436 65.979-65.455v-63.372h-66.495zm0-320.417c-35.576 0-64.415 28.84-64.415 64.414 0 35.576 28.84 64.415 64.415 64.415h66.495V31.377h-66.495zm97.873 128.829h66.495c35.576 0 64.415-28.839 64.415-64.415 0-35.575-28.839-64.414-64.415-64.414h-66.495v128.829z' />
    </svg>
  );
}

export default IconFigma;
