import { Email } from '../../assets/icons';

export const Contact = () => {
  return (
    <section className='bg-white bg-gray-900'>
      <div className='py-8 lg:py-16 px-4 mx-auto max-w-screen-md'>
        <h2 className='mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900'>
          Get In Touch!
        </h2>
        <p className='mb-4 font-light text-center text-gray-500 text-gray-400 sm:text-xl'>
          Feel free to reach out if you're looking for a developer, have a
          question, or just want to connect.
        </p>

        <p className='flex flex-row justify-center font-light text-center text-gray-500 text-gray-400 sm:text-xl font-semibold'>
          <span className='flex flex-row items-center mr-2'>
            <Email />
          </span>
          prayas.lashkari98@gmail.com
        </p>
      </div>
    </section>
  );
};
