import { AppStoreIOS, Playstore, Web } from '../../assets/icons';

export const Timeline = () => {
  return (
    <section className='bg-gray-800 text-gray-100'>
      <div className='container max-w-5xl px-4 py-12 mx-auto'>
        <div className='grid gap-4 mx-4 sm:grid-cols-12'>
          <div className='col-span-12 sm:col-span-3'>
            <div className='text-center sm:text-left mb-14 before:block before:w-24 before:h-3 before:mb-5 before:rounded-md before:mx-auto sm:before:mx-0 before:bg-violet-400'>
              <h3 className='text-3xl font-semibold'>Journey So Far...</h3>
              {/*   <span className='text-sm font-bold  uppercase text-gray-400'>
                3+ years
              </span> */}
            </div>
          </div>
          <div className='relative col-span-12 px-4 space-y-6 sm:col-span-9'>
            <div className='col-span-12 space-y-12 relative px-4 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:bg-gray-700'>
              <div className='flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-violet-400'>
                <h3 className='text-xl font-semibold '>
                  B.Tech (CSE),
                  <a
                    className='ml-1 underline font-bold'
                    href='https://www.linkedin.com/school/jaypee-institute-of-information-technology/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Jaypee Institute of Information Technology, Noida
                  </a>
                </h3>
                <time className='text-xs uppercase text-gray-400'>
                  Jul 2016 - Jul 2020
                </time>
                <p className='mt-4'>
                  <ul className='list-disc ml-3 space-y-2'>
                    {/*  <li>
                      Developed a Video Conferencing App for both Mobile & App
                      Platform. Worked on micro-interactions for both React and
                      React Native app.
                    </li>

                    <li>
                      Implemented various critical features like video-calling,
                      virtual Background, push-to-talk, breakout rooms, chat
                      system.
                    </li>

                    <li>
                      Playstore - https://t.ly/FSpn, AppStore -
                      https://t.ly/pdMc
                    </li> */}
                  </ul>
                </p>
              </div>
              <div className='flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-violet-400'>
                <h3 className='text-xl font-semibold '>
                  Frontend Engineer,
                  <a
                    className='ml-1 underline font-bold'
                    href='https://www.linkedin.com/company/thumbstacktechnologies/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Thumbstack Technologies
                  </a>
                </h3>
                <time className='text-xs uppercase text-gray-400'>
                  Jan 2020 - Jul 2021
                </time>
                <p className='mt-4'>
                  <ul className='list-disc ml-3 space-y-2'>
                    <li>
                      Developed a Video Conferencing App for both Mobile & App
                      Platform. Worked on micro-interactions for both React and
                      React Native app.
                    </li>

                    <li>
                      Implemented various critical features like video-calling,
                      virtual Background, push-to-talk, breakout rooms, chat
                      system.
                    </li>

                    <li>
                      <span className='flex flex-row'>
                        Checkout the project
                        <a
                          className='flex flex-row items-center ml-1 underline font-bold'
                          href='https://play.google.com/store/apps/details?id=com.callwall&hl=en_IN&gl=US'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <Playstore /> <span className='ml-1'>Playstore </span>
                        </a>
                        <span className='ml-1'>| </span>
                        <a
                          className='flex flex-row items-center ml-1 underline font-bold'
                          href='https://apps.apple.com/in/app/callwall/id1557818612'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <AppStoreIOS />{' '}
                          <span className='ml-1'>App Store</span>
                        </a>
                      </span>
                    </li>
                  </ul>
                </p>
              </div>
              <div className='flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-violet-400'>
                <h3 className='text-xl font-bold '>
                  Product Engineer,{' '}
                  <a
                    className='ml-1 underline font-bold'
                    href='https://www.linkedin.com/company/squadstack/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Squadstack
                  </a>
                </h3>
                <time className='text-xs  uppercase text-gray-400'>
                  Jul 2021 - Sept 2023
                </time>
                <p className='mt-4'>
                  <ul className='list-disc ml-3 space-y-2'>
                    <li>
                      Collaborated with team to create responsive and intuitive
                      user interfaces for Mobile & Web Apps.
                    </li>

                    <li>
                      Successfully migrated 'Squadrun' to React Native, boosting
                      performance, increasing user experience and development
                      efficiency resulting in
                      <a
                        className='ml-1 underline font-bold'
                        href='https://www.investopedia.com/terms/m/monthly-active-user-mau.asp'
                        target='_blank'
                        rel='noreferrer'
                      >
                        50% growth in MAUs
                      </a>
                      .
                    </li>

                    <li>
                      Worked with various third-party libraries and
                      native-modules to build critical features.
                    </li>

                    <li> Implemented CI pipeline using Github Actions.</li>

                    <li>
                      Developed a React.js platform for agent onboarding in 2
                      weeks, which automated
                      <span className='ml-1 underline font-bold'>
                        more than 70% of manual tasks
                      </span>
                      <span> </span> and resulted in boosting user acquisition
                      and engagement.
                    </li>

                    <li>
                      Contributed to the documentation and knowledge sharing
                      within the team, enabling smoother onboarding for new team
                      members.
                    </li>

                    <li>
                      🚀 Hosted numerous fun events to increase collaboration
                      within the team.
                    </li>

                    <li>
                      <span className='flex flex-row'>
                        Checkout the project
                        <a
                          className='flex flex-row items-center ml-1 underline font-bold'
                          href='https://play.google.com/store/apps/details?id=com.squad.run&hl=en_IN&gl=US'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <Playstore /> <span className='ml-1'>Playstore </span>
                        </a>
                        <span className='ml-1'>| </span>
                        <a
                          className='flex flex-row items-center ml-1 underline font-bold'
                          href='https://onboarding.squadstack.com/auth'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <Web /> <span className='ml-1'>Web App</span>
                        </a>
                      </span>
                    </li>
                  </ul>
                </p>
              </div>

              <div className='flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-violet-400'>
                <h3 className='text-xl font-bold '>
                  Software Engineer,{' '}
                  <a
                    className='ml-1 underline font-bold'
                    href='https://www.linkedin.com/company/h1co/mycompany/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    H1 Life Sciences
                  </a>
                </h3>
                <time className='text-xs  uppercase text-gray-400'>
                  Oct 2023 - Aug 2024
                </time>
                <p className='mt-4'>
                  <ul className='list-disc ml-3 space-y-2'>
                    <li>
                      Currently working with the mobile app team and leading the
                      iOS App initiative.
                    </li>

                    <li>
                      Actively Collaborating with the design team and in UX
                      research to build the best user experience.
                    </li>
                    <li>
                      Implemented push notifications and streamlined the release
                      process for iOS.
                    </li>

                    <li>
                      <span className='flex flex-row'>
                        Checkout the project
                        <a
                          className='flex flex-row items-center ml-1 underline font-bold'
                          href='https://apps.apple.com/sa/app/h1-mobile/id6446346041'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <Web /> <span className='ml-1'>iOS App</span>
                        </a>
                      </span>
                    </li>
                  </ul>
                </p>
              </div>

              <div className='flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-violet-400'>
                <h3 className='text-xl font-bold '>
                  M.S. in Computer Software Engineering,{' '}
                  <a
                    className='ml-1 underline font-bold'
                    href='https://www.linkedin.com/company/h1co/mycompany/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Northeastern University
                  </a>
                </h3>
                <time className='text-xs  uppercase text-gray-400'>
                  Sept 2024 - May 2026
                </time>
                <p className='mt-4'>
                  <ul className='list-disc ml-3 space-y-2'>
                    <li>Back to School!!</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
