import { Fragment } from 'react';
import { Download, Github } from '../../assets/icons';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Popover, Transition } from '@headlessui/react';
import MyPicture from '../../assets/images/my_picture.jpg';

const TYPOGRAPHY = () => {
  return (
    <span className='relative inline-flex ml-2 overflow-hidden p-2'>
      <div className='flex flex-row'>
        <div className='animate-fall translate-y-[-150%]'>d</div>
        <div className='animate-fall animation-delay-[50ms] translate-y-[-150%]'>
          e
        </div>
        <div className='animate-fall animation-delay-[100ms] translate-y-[-150%]'>
          v
        </div>
        <div className='animate-fall animation-delay-[150ms] translate-y-[-150%]'>
          e
        </div>
        <div className='animate-fall animation-delay-[200ms] translate-y-[-150%]'>
          l
        </div>
        <div className='animate-fall animation-delay-[250ms] translate-y-[-150%]'>
          o
        </div>
        <div className='animate-fall animation-delay-[300ms] translate-y-[-150%]'>
          p
        </div>
        <div className='animate-fall animation-delay-[350ms] translate-y-[-150%]'>
          e
        </div>
        <div className='animate-fall animation-delay-[400ms] translate-y-[-150%]'>
          r
        </div>
      </div>

      <span className='absolute top-0 bottom-0'>
        <div className='flex flex-row'>
          <div className='animate-fall animation-delay-[1550ms] translate-y-[-150%]'>
            d
          </div>
          <div className='animate-fall animation-delay-[1600ms] translate-y-[-150%]'>
            e
          </div>
          <div className='animate-fall animation-delay-[1650ms] translate-y-[-150%]'>
            s
          </div>
          <div className='animate-fall animation-delay-[1700ms] translate-y-[-150%]'>
            i
          </div>
          <div className='animate-fall animation-delay-[1750ms] translate-y-[-150%]'>
            g
          </div>
          <div className='animate-fall animation-delay-[1800ms] translate-y-[-150%]'>
            n
          </div>
          <div className='animate-fall animation-delay-[1850ms] translate-y-[-150%]'>
            e
          </div>
          <div className='animate-fall animation-delay-[1900ms] translate-y-[-150%]'>
            r
          </div>
        </div>
      </span>
    </span>
  );
};

type HeroProps = {
  onSkillClick: (e: { preventDefault: () => void }) => void;
  onExperienceClick: (e: { preventDefault: () => void }) => void;
  onContactClick: (e: { preventDefault: () => void }) => void;
};

export const Hero = ({
  onSkillClick,
  onExperienceClick,
  onContactClick,
}: HeroProps) => {
  const navigation = [
    { name: 'Skills', onClickHandler: onSkillClick },
    { name: 'Experience', onClickHandler: onExperienceClick },
    { name: 'Contact', onClickHandler: onContactClick },
  ];

  return (
    <div className='relative overflow-hidden bg-white'>
      <div className='mx-auto max-w-7xl'>
        <div className='relative z-10 bg-white pb-8 sm:pb-16 md:pb-20 lg:w-full lg:pb-28 xl:pb-32'>
          <svg
            className='absolute inset-y-0 right-0 hidden h-full w-48 translate-x-1/2 transform text-white lg:block'
            fill='currentColor'
            viewBox='0 0 100 100'
            preserveAspectRatio='none'
            aria-hidden='true'
          >
            <polygon points='50,0 100,0 50,100 0,100' />
          </svg>

          <Popover>
            <div className='relative px-4 pt-6 sm:px-6 lg:px-8'>
              <nav
                className='relative flex items-center justify-between sm:h-10 lg:justify-center sm:justify-center'
                aria-label='Global'
              >
                <div className='flex flex-shrink-0 flex-grow items-center lg:flex-grow-0'>
                  <div className='flex w-full items-center justify-between md:w-auto'>
                    {/*  <a href='#'>
                    <span className='sr-only'>Your Company</span>
                    <img
                      alt='Your Company'
                      className='h-8 w-auto sm:h-10'
                      src='https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600'
                    />
                  </a> */}
                    <div className='-mr-2 flex items-center md:hidden'>
                      <Popover.Button className='inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
                        <span className='sr-only'>Open main menu</span>
                        <Bars3Icon className='h-6 w-6' aria-hidden='true' />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className='hidden md:ml-10 md:block md:space-x-8 md:pr-4'>
                  {navigation.map((item) => (
                    <span
                      key={item.name}
                      className='cursor-pointer font-medium text-gray-500 hover:text-gray-900'
                      onClick={item.onClickHandler}
                    >
                      {item.name}
                    </span>
                  ))}
                  {/*  <a
                  href='#'
                  className='font-medium text-indigo-600 hover:text-indigo-500'
                >
                  Log in
                </a> */}
                </div>
              </nav>
            </div>

            <Transition
              as={Fragment}
              enter='duration-150 ease-out'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='duration-100 ease-in'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Popover.Panel
                focus
                className='absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden'
              >
                <div className='overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5'>
                  <div className='flex items-center justify-between px-5 pt-4'>
                    <div>
                      <img
                        className='h-8 w-auto'
                        src='https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600'
                        alt=''
                      />
                    </div>
                    <div className='-mr-2'>
                      <Popover.Button className='inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
                        <span className='sr-only'>Close main menu</span>
                        <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className='space-y-1 px-2 pt-2 pb-3'>
                    {navigation.map((item) => (
                      <span
                        key={item.name}
                        onClick={item.onClickHandler}
                        className='cursor-pointer block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900'
                      >
                        {item.name}
                      </span>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <main className='mx-auto mt-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28'>
            <div className='sm:text-center lg:text-center'>
              <div className='w-full flex flex-row sm:justify-center justify-left'>
                <img
                  alt='Your Company'
                  className='mb-6 rounded-full h-[150px] w-[150px] object-cover border-[1px] border-indigo-200'
                  src={MyPicture}
                />
              </div>
              <span className='text-lg'>Hey there! I'm Prayas 👋🏻 </span>
              <h1 className='text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl'>
                <span>
                  I'm a
                  <TYPOGRAPHY />
                </span>
                <span className='block text-indigo-600'>
                  based out of Boston, MA
                </span>
              </h1>
              <p className='mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-auto'>
                Experienced front-end developer with 4+ years in UX &
                development, known for strong design skills and quick technology
                adoption.
              </p>
              <div className='mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center'>
                <div className='rounded-md shadow'>
                  <a
                    href='https://drive.google.com/file/d/10O5EDGNuerB7M7gblLNIeYtAfMDnpXaL/view?usp=sharing'
                    className='flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 md:py-4 md:px-10 md:text-lg'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <span className='mr-2'>
                      <Download />
                    </span>
                    Resume
                  </a>
                </div>
                <div className='mt-3 sm:mt-0 sm:ml-3'>
                  <a
                    href='https://github.com/prayaslashkari'
                    className='flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-100 px-8 py-3 text-base font-medium text-indigo-700 hover:bg-indigo-200 md:py-4 md:px-10 md:text-lg'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <span className='mr-2'>
                      <Github />
                    </span>
                    Github
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};
