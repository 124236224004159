// icon:brand-redux | Tabler Icons https://tablericons.com/ | Csaba Kissi
import * as React from 'react';

function IconBrandRedux(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      viewBox='0 0 24 24'
      height='1em'
      width='1em'
      {...props}
    >
      <path stroke='none' d='M0 0h24v24H0z' />
      <path d='M16.54 7c-.805-2.365-2.536-4-4.54-4-2.774 0-5.023 2.632-5.023 6.496 0 1.956 1.582 4.727 2.512 5.996' />
      <path d='M4.711 11.979C3.055 13.856 2.497 16.164 3.5 17.89c1.387 2.39 5.138 2.831 8.501.9 1.703-.979 2.875-3.362 3.516-4.798' />
      <path d='M15.014 19.99c2.511 0 4.523-.438 5.487-2.1 1.387-2.39-.215-5.893-3.579-7.824-1.702-.979-4.357-1.235-5.927-1.07' />
      <path
        fill='currentColor'
        d='M10.493 9.862c.48.276 1.095.112 1.372-.366a.997.997 0 00-.367-1.365 1.007 1.007 0 00-1.373.366.997.997 0 00.368 1.365z'
      />
      <path
        fill='currentColor'
        d='M10.494 15.492 A1.005 1 0 0 1 9.489 16.492 A1.005 1 0 0 1 8.484000000000002 15.492 A1.005 1 0 0 1 10.494 15.492 z'
      />
      <path
        fill='currentColor'
        d='M16.521 13.993 A1.005 1 0 0 1 15.516 14.993 A1.005 1 0 0 1 14.511 13.993 A1.005 1 0 0 1 16.521 13.993 z'
      />
    </svg>
  );
}

export default IconBrandRedux;
