import {
  ReactOutlined,
  NextJSOutlined,
  TypeScriptSolid,
  JavascriptSolid,
  HTMLSolid,
  SassOutlined,
  CSSSolid,
  ReduxOutlined,
  TailwindCSS,
  FigmaOutlined,
} from '../../assets/icons';

/* 

h1::before {  
  transform: scaleX(0);
  transform-origin: bottom right;
}

h1:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

h1::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  inset: 0 0 0 0;
  background: hsl(200 100% 80%);
  z-index: -1;
  transition: transform .3s ease;
}

//
h1 {
  position: relative;
  font-size: 5rem;
}

*/
export const Skills = () => {
  return (
    <div className='px-4 md:px-24 lg:px-8 py-16 mx-auto w-full lg:py-20 bg-gray-800 text-gray-100'>
      <h2
        className='relative transition-all w-max-content
          before:w-0 before:h-full before:z-[-1]  before:absolute before:bottom-0 before:right-0 before:bg-blue-400 before:transition-all before:duration-500
          hover:before:w-full hover:before:left-0 hover:before:bg-red-500   mb-8 text-4xl font-bold text-center'
      >
        Tech Stack
      </h2>

      <ul className='grid gap-3 md:grid-cols-2 lg:grid-cols-3 lg:w-2/3 justify-center mx-auto'>
        <li className='flex items-center justify-center space-x-2'>
          <ReactOutlined color={'#5ED2F3'} />
          <span className='text-lg font-semibold'>React</span>
        </li>

        <li className='flex items-center justify-center space-x-2'>
          <ReactOutlined color={'#5ED2F3'} />
          <span className='text-lg font-semibold'>React Native</span>
        </li>
        <li className='flex items-center justify-center space-x-2'>
          <NextJSOutlined color={'#FFFFFF'} />
          <span className='text-lg font-semibold'>NextJS</span>
        </li>
        <li className='flex items-center justify-center space-x-2'>
          <TypeScriptSolid color={'#2E78C7'} />
          <span className='text-lg font-semibold'>Typescript</span>
        </li>
        <li className='flex items-center justify-center space-x-2'>
          <JavascriptSolid color={'#E8D44D'} />
          <span className='text-lg font-semibold'>Javascript</span>
        </li>
        <li className='flex items-center justify-center space-x-2'>
          <ReduxOutlined color={'#7248b6'} />
          <span className='text-lg font-semibold'>Redux</span>
        </li>
        <li className='flex items-center justify-center space-x-2'>
          <HTMLSolid color={'#D35835'} />
          <span className='text-lg font-semibold'>Html</span>
        </li>
        <li className='flex items-center justify-center space-x-2'>
          <CSSSolid color={'#304CDC'} />
          <span className='text-lg font-semibold'>CSS</span>
        </li>
        <li className='flex items-center justify-center space-x-2'>
          <SassOutlined color={'#C76394'} />
          <span className='text-lg font-semibold'>Sass</span>
        </li>

        <li className='flex items-center justify-center space-x-2'>
          <TailwindCSS color={'#37BCF8'} />
          <span className='text-lg font-bold'>TailwindCSS</span>
        </li>
        <li className='flex items-center justify-center space-x-2'>
          <FigmaOutlined />
          <span className='text-lg font-bold'>Figma</span>
        </li>
      </ul>
    </div>
  );
};
