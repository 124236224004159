import React, { useRef } from 'react';
import { Timeline, Footer, Skills, Contact, Hero } from './components';

export default function App() {
  const skillRef = useRef<null | HTMLDivElement>(null);
  const experienceRef = useRef<null | HTMLDivElement>(null);
  const contactRef = useRef<null | HTMLDivElement>(null);

  const onSkillClick = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    skillRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const onExperienceClick = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    experienceRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const onContactClick = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    contactRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <>
      <Hero
        onContactClick={onContactClick}
        onExperienceClick={onExperienceClick}
        onSkillClick={onSkillClick}
      />

      <div ref={skillRef}>
        <Skills />
      </div>

      <div ref={experienceRef}>
        <Timeline />
      </div>

      <div ref={contactRef}>
        <Contact />
      </div>
      <Footer />
    </>
  );
}
